<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="bg-blue-dark">
    <Container class="py-16 lg:py-36">
      <div class="grid lg:grid-cols-3">
        <div class="mb-16 flex flex-col items-start lg:mb-0">
          <nuxt-img
            v-for="image in getImagesCloudinary(data?.row_one_left_logo)"
            :key="image.url"
            :src="image.url"
            alt="Logo"
            class="mb-14 w-80 cursor-pointer lg:mb-16"
            width="320"
            height="130"
            loading="lazy"
            @click="clickToLogo"
          />

          <div
            v-if="data?.row_one_left_copy?.value"
            class="mb-10 space-y-4 font-sans text-base leading-5 text-white lg:mb-6 lg:text-lg lg:leading-6"
            v-html="data?.row_one_left_copy?.value"
          ></div>
          <LinkSecondary
            v-if="data?.row_one_left_link_int"
            :title="data?.row_one_left_link_text"
            :link="data?.row_one_left_link_int"
          />
        </div>
        <div class="w-full"></div>
        <div class="w-full">
          <div class="mb-3 flex flex-wrap items-center lg:mt-16">
            <nuxt-img
              v-for="image in getImagesCloudinary(data?.row_one_right_images)"
              :key="image.url"
              :src="image.url"
              alt="Logo"
              class="mb-4 mr-4 max-h-12 max-w-[45%] lg:mr-6 lg:max-h-16"
              height="130"
              loading="lazy"
            />
          </div>
          <div
            class="lg:blog hidden font-sans text-base leading-5 text-white lg:text-lg lg:leading-6"
          >
            Swish for the animals:
          </div>
          <div class="mb-4 font-serif text-2xl font-bold text-white lg:mb-4 lg:text-3xl">
            {{ data?.row_one_right_heading }}
          </div>
          <div class="flex flex-col">
            <FormSwish v-if="config.public.SWISH_FOOTER" />
            <div v-else>
              <Link
                :title="$t('one-time-gift')"
                class="hover:bg-blue-lighter hover:text-blue lg:max-w-[400px]"
                :link="$t('one-time-gift.footer.url')"
                :external="true"
              />
              <LinkSecondary
                v-if="data?.row_one_right_link_int"
                :title="data?.row_one_right_link_text"
                :link="data?.row_one_right_link_int"
                class="mt-16"
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
    <Divider />
    <Container class="pb-16 lg:pb-20">
      <div class="grid lg:grid-cols-3">
        <div class="mt-16 lg:mt-20">
          <span
            v-if="data?.row_two_left_heading"
            class="mb-8 font-serif text-28 text-2xl font-bold leading-none text-white lg:mb-10 lg:leading-9"
          >
            {{ data?.row_two_left_heading }}
          </span>
          <div
            v-if="data?.row_two_left_copy?.value"
            class="mt-6 max-w-[80%] font-serif text-2xl text-white"
            v-html="data?.row_two_left_copy?.value"
          ></div>
          <LinkSecondary
            v-if="data?.row_two_left_link_int"
            :title="data?.row_two_left_link_text"
            :link="data?.row_two_left_link_int"
            class="mt-10 lg:mt-16"
          />
        </div>
        <div class="mt-16 lg:mt-20">
          <span
            v-if="data?.row_two_mid_heading"
            class="mb-8 font-serif text-28 text-2xl font-bold leading-none text-white lg:mb-10 lg:leading-9"
          >
            {{ data?.row_two_mid_heading }}
          </span>
          <div
            v-if="data?.row_two_mid_copy?.value"
            class="mt-6 font-serif text-xl uppercase text-white [&_a]:mb-3.5 [&_a]:inline-block hover:[&_a]:underline"
            v-html="data?.row_two_mid_copy?.value"
          ></div>
          <LinkSecondary
            v-if="data?.row_two_mid_link_int"
            :title="data?.row_two_mid_link_text"
            :link="data?.row_two_mid_link_int"
            class="mt-10 lg:mt-16"
          />
        </div>
        <div class="mt-16 lg:mt-20">
          <span
            v-if="data?.row_two_right_heading"
            class="mb-8 font-serif text-28 text-2xl font-bold leading-none text-white lg:mb-10 lg:leading-9"
          >
            {{ data?.row_two_right_heading }}
          </span>
          <div
            v-if="data?.row_two_right_copy?.value"
            class="mt-6 font-serif text-xl uppercase text-white [&_a]:mb-3.5 [&_a]:inline-block hover:[&_a]:underline"
            v-html="data?.row_two_right_copy?.value"
          ></div>
          <LinkSecondary
            v-if="data?.row_two_right_link_int"
            :title="data?.row_two_right_link_text"
            :link="data?.row_two_right_link_int"
            class="mt-10 lg:mt-16"
          />
        </div>
      </div>
    </Container>
    <Divider />
    <Container class="flex flex-col items-center py-16 lg:py-20">
      <span class="mb-12 font-serif text-2xl font-bold text-white lg:hidden">
        {{ $t('follow-us') }}
      </span>
      <SocialLinks :links="data?.row_three_socmed_urls" />
      <LanguageSwitcher
        v-if="config.public.hideMenu"
        class="my-20"
        class-icon="text-white"
        class-text="text-white"
      />
      <div class="mt-20 flex flex-col items-center justify-center lg:flex-row">
        <span
          v-if="data?.row_three_copyright"
          class="text-center text-base text-white lg:text-lg"
          v-html="data?.row_three_copyright?.replace('[year]', new Date().getFullYear())"
        ></span>
      </div>
    </Container>
  </div>
</template>

<script setup>
import { useRoute, useRouter, useRuntimeConfig } from '#app';
import { buildUrl } from 'cloudinary-build-url';
import { Link } from 'refresh-ui';
import Container from '@/components/atoms/Container.vue';
import LinkSecondary from '@/components/atoms/LinkSecondary.vue';
import Divider from '@/components/atoms/Divider.vue';
import SocialLinks from '@/components/molecules/SocialLinks.vue';
import LanguageSwitcher from '@/components/molecules/LanguageSwitcher.vue';
import FormSwish from '@/components/molecules/FormSwish.vue';

const route = useRoute();
const router = useRouter();
const config = useRuntimeConfig();

defineProps({
  data: Object,
});

const clickToLogo = () => {
  if (route.path === '/') {
    window.scrollTo(0, 0);
    return;
  }
  router.push({ path: '/' });
};

const getImagesCloudinary = images => {
  if (!images || images.length === 0) return;

  return images.map(image => {
    const url = buildUrl(image, {
      cloud: {
        cloudName: config.public.cloudname,
      },
      transformations: {
        format: 'svg',
      },
    });

    return {
      type: 'image',
      url,
    };
  });
};
</script>
