<template>
  <div class="flex items-center space-x-4">
    <NuxtLink
      v-for="link in links"
      :key="link"
      :to="link"
      target="_blank"
      class="cursor-pointer text-blue hover:text-blue-dark"
    >
      <component :is="getIcon(link)" class="h-6 w-6" />
    </NuxtLink>
  </div>
</template>

<script setup>
import Linkedin from '@/components/atoms/Icons/Linkedin.vue';
import Facebook from '@/components/atoms/Icons/Facebook.vue';
import Instagram from '@/components/atoms/Icons/Instagram.vue';
import Twitter from '@/components/atoms/Icons/Twitter.vue';
import YouTube from '@/components/atoms/Icons/YouTube.vue';
import TikTokOutline from '@/components/atoms/Icons/TikTokOutline.vue';

defineProps({
  links: Array,
});

const getIcon = link => {
  const linkLowerCase = link.toLowerCase();

  if (linkLowerCase.includes('youtube')) {
    return YouTube;
  }
  if (linkLowerCase.includes('tiktok')) {
    return TikTokOutline;
  }
  if (linkLowerCase.includes('instagram')) {
    return Instagram;
  }
  if (linkLowerCase.includes('twitter')) {
    return Twitter;
  }
  if (linkLowerCase.includes('facebook')) {
    return Facebook;
  }
  if (linkLowerCase.includes('linkedin')) {
    return Linkedin;
  }
};
</script>
