<template>
  <SwishSpinner
    v-if="showSpinner"
    :show-qr-code="showQrCode"
    :show-loader="showLoader"
    :qr="swishResponse.qr"
    :swish-app-url="swishResponse.swishAppUrl"
    :show-link="showLink"
    :show-retry="isError"
    @onRetry="handleRetry"
    @onClose="handleStop"
  />
  <form class="lg:max-w-[400px]">
    <template v-if="isDesktop">
      <div v-if="showQrCode">
        <Input
          v-model="sum"
          :v$="v$.sum"
          type="number"
          min="0"
          label="Summa att swisha:"
          style-label="text-sm lg:text-base text-white"
          :currency="$t('currency')"
          style-input="w-full text-blue text-sm lg:text-base font-bold"
          :right="otherRightString"
        />
        <Button
          :title="ERROR_STATUSES.includes(status) ? 'Försök igen' : 'Swisha din donation'"
          class="mt-6 w-full hover:bg-blue-lighter hover:text-blue lg:max-w-[400px]"
          @click.prevent="handleFormMcommerce({ donationAmount: sum, qrCode: true })"
          :disabled="v$.sum.$invalid ? true : undefined"
        />
        <FormSwishTerms class="mt-4" />
      </div>
      <MultiStepForm :steps="blocks" v-else>
        <template #[blocks.step1.name]="{ nextStep }">
          <Input
            v-model="sum"
            :v$="v$.sum"
            type="number"
            min="0"
            label="Summa att swisha:"
            style-label="text-sm lg:text-base text-white"
            style-input="w-full text-blue text-sm lg:text-base font-bold"
            style-error="text-white"
            :right="otherRightString"
          />
          <Button
            title="Nästa"
            class="mt-6 w-full hover:bg-blue-lighter hover:text-blue lg:max-w-[400px]"
            @click.prevent="nextStep"
            :disabled="v$.sum.$invalid ? true : undefined"
          />
        </template>
        <template #[blocks.step2.name]="{ prevStep }">
          <Input
            v-model="mobile"
            :v$="v$.mobile"
            label="Ditt mobilnummer:"
            style-label="text-sm lg:text-base text-white"
            style-input="w-full text-blue text-sm lg:text-base font-bold"
            style-error="text-white"
          />
          <div
            v-if="!v$.mobile.$invalid || !v$.mobile.$dirty"
            class="mt-2 w-full font-sans text-xs leading-normal text-black text-white prose-a:text-blue prose-a:no-underline hover:prose-a:text-blue-dark lg:text-sm lg:leading-relaxed"
          >
            {{ t('mobile-number.swedish.description') }}
          </div>
          <Button
            :title="
              ERROR_STATUSES.includes(status) ? 'Försök igen' : 'Swisha din donation'
            "
            class="mt-6 w-full hover:bg-blue-lighter hover:text-blue lg:max-w-[400px]"
            @click.prevent="handleFormEcommerce({ donationAmount: sum })"
          />

          <div class="mt-4">
            <button
              @click.prevent="prevStep"
              class="text-base font-bold text-blue hover:text-white"
            >
              &lt; <span class="underline">tillbaka</span>
            </button>
            <FormSwishTerms />
          </div>
        </template>
      </MultiStepForm>

      <div
        v-if="swishResponse.message && ERROR_STATUSES.includes(status)"
        class=""
        role="alert"
      >
        <span class="mr-2 flex-auto text-left font-semibold">
          <span>{{ swishResponse.message }}</span>
        </span>
      </div>
    </template>
    <template v-else>
      <Input
        v-model="sum"
        :v$="v$.sum"
        type="number"
        min="0"
        label="Summan att donera:"
        style-label="text-sm lg:text-base text-white"
        style-input="w-full text-blue text-sm lg:text-base font-bold"
        :right="otherRightString"
      />
      <Button
        :title="ERROR_STATUSES.includes(status) ? 'Försök igen' : 'Swisha din donation'"
        class="mt-6 w-full hover:bg-blue-lighter hover:text-blue"
        @click.prevent="handleFormMcommerce({ donationAmount: sum })"
        :disabled="v$.sum.$invalid ? true : undefined"
      />
    </template>
  </form>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import { ref, computed } from 'vue';
import { Button, Input } from 'refresh-ui';
import { flagError } from 'error-handling';
import { useVuelidate } from '@vuelidate/core';
import { swedishMobile } from 'vuelidate-validators';
import { PAYMENT_STATUS, ERROR_STATUSES } from 'swish';
import { minValue, helpers, required } from '@vuelidate/validators';
import MultiStepForm from '~/components/molecules/MultiStepForm.vue';
import SwishSpinner from '~/components/molecules/SwishSpinner.vue';
import FormSwishTerms from '~/components/atoms/FormSwishTerms.vue';

const { $rollbar } = useNuxtApp();
const { t } = useI18n();

const { isDesktop } = useDevice();

const sum = ref('200');
const minSum = ref('10');
const mobile = ref('');

const status = ref(null);

const showSpinner = ref(false);
const showLoader = ref(true);
const showLink = ref(false);
const isError = ref(false);

// switch on the desktop to use qrcode or not.
const showQrCode = ref(false);

const swishResponseInit = {
  operationId: null,
  qr: '',
  swishAppUrl: '',
  message: '',
};

const blocks = computed(() => ({
  step1: {
    name: 'step1',
  },
  step2: {
    name: 'step2',
  },
}));

const swishResponse = ref(swishResponseInit);

const rules = computed(() => {
  const localRules: { [key: string]: any } = {
    sum: {
      minValue: helpers.withMessage(
        t('sum.min-sum.error', { sum: minSum.value, currency: t('currency') }),
        minValue(minSum.value),
      ),
    },
  };
  if (!showQrCode.value && isDesktop) {
    localRules['mobile'] = {
      swedishMobile: helpers.withMessage(t('mobile-number.swedish.error'), swedishMobile),
      required: helpers.withMessage(t('mobile-number.swedish.error'), required),
    };
  }

  return localRules;
});

const v$ = useVuelidate(rules, { sum, mobile });

const otherRightString = `<span class='absolute right-5 top-3 lg:top-4 text-sm font-bold text-blue lg:text-lg'>${t(
  'currency',
)}</span>`;

interface IDonationRequestBody {
  amount: string;
  pageSuccess: string;
  pageRejected: string;
}

const createPaymentPoller = () => {
  let stopPolling = false;

  const pollCheckPayment = async (operationId: string) => {
    const interval = 2000; // Interval in milliseconds (e.g., 5000ms = 5 seconds)

    while (!stopPolling) {
      try {
        const response = await $fetch(`/api/swish?id=${operationId}`);
        status.value = response.status;

        if (response.status === PAYMENT_STATUS.PAID) {
          console.log('Payment status is paid!');
          swishResponse.value = swishResponseInit;
          showSpinner.value = false;
          // TODO: See if we can add hashId and name args to this url
          await navigateTo('/swish/success');
          break;
        }

        if (ERROR_STATUSES.includes(response.status)) {
          console.log('Payment status:', response.status);
          swishResponse.value = swishResponseInit;
          isError.value = true;
          showLoader.value = false;
          showLink.value = false;
          break;
        }

        if (response.status !== PAYMENT_STATUS.CREATED) {
          swishResponse.value = swishResponseInit;
          showLoader.value = true;
        }

        console.log('Payment status:', response.status);
      } catch (error) {
        console.error('Error occurred:', error);
        break;
      }

      await new Promise(resolve => setTimeout(resolve, interval));
    }
  };

  const stopPollingFn = () => {
    stopPolling = true;
  };

  return { pollCheckPayment, stopPolling: stopPollingFn };
};

const { pollCheckPayment, stopPolling } = createPaymentPoller();

const handleFormMcommerce = async ({
  donationAmount,
  qrCode = false,
}: {
  donationAmount: string;
  qrCode?: boolean;
}) => {
  isError.value = false;
  v$.value.$validate();
  if (v$.value.$invalid) return false;
  try {
    showSpinner.value = true;
    swishResponse.value = swishResponseInit;

    if (!qrCode) {
      showLink.value = true;
      showQrCode.value = false;
    }

    showLoader.value = false;

    const requestBody: IDonationRequestBody = {
      amount: donationAmount,
      pageSuccess: '/swish/success',
      pageRejected: '/swish/reject',
    };

    const response = await $fetch('/api/swish', {
      method: 'POST',
      body: JSON.stringify(requestBody),
    });
    pollCheckPayment(response.operationId);

    swishResponse.value = response;
  } catch (error) {
    flagError(error, 'swish/handleFormMcommerce', $rollbar);
    console.log(error);
  }
};

const handleFormEcommerce = async ({ donationAmount }: { donationAmount: string }) => {
  try {
    showLoader.value = true;
    isError.value = false;
    v$.value.$validate();

    if (v$.value.$invalid) return false;

    showSpinner.value = true;
    swishResponse.value = swishResponseInit;
    const mobileNumber = mobile.value.startsWith('+')
      ? mobile.value.slice(1)
      : mobile.value;
    const response = await $fetch('/api/swish', {
      method: 'POST',
      body: {
        amount: donationAmount,
        payerAlias: mobileNumber,
      },
    });

    swishResponse.value = response;
    if (!response.errors) {
      pollCheckPayment(response.operationId);
    }
  } catch (error) {
    flagError(error, 'swish/handleFormEcommerce', $rollbar);
    console.log(error);
  }
};

const handleRetry = async () => {
  stopPolling();
  if (isDesktop && showQrCode.value) {
    handleFormMcommerce({ donationAmount: sum.value, qrCode: showQrCode.value });
    return;
  }

  if (isDesktop) {
    handleFormEcommerce({ donationAmount: sum.value });
    return;
  }
  handleFormMcommerce({ donationAmount: sum.value });
};

const handleStop = () => {
  stopPolling();
  showSpinner.value = false;
};
</script>
