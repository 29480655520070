<!-- eslint-disable vue/no-v-html -->
<template>
  <Transition
    :duration="{ enter: 500, leave: 500 }"
    enter-from-class="opacity-0"
    leave-to-class="opacity-0"
    enter-active-class="transition-opacity duration-500"
    leave-active-class="transition-opacity duration-500"
  >
    <div
      v-show="menuState.menu"
      class="fixed inset-0 z-50 overflow-auto bg-white xl:hidden"
    >
      <button
        class="absolute right-4 top-4 z-50 p-2 hover:text-blue-dark active:bg-transparent"
        @click="closeMenu"
      >
        <AtomsIconsClose />
      </button>
      <Transition
        enter-from-class="opacity-0"
        leave-to-class="opacity-0"
        enter-active-class="transition duration-200 delay-500"
        leave-active-class="transition duration-100"
      >
        <button
          v-show="menuLevel > 0"
          class="absolute left-4 top-4 z-50 p-2 text-black"
          @click="goBack"
        >
          <AtomsIconsArrowLeft />
        </button>
      </Transition>
      <template v-for="(item, index) in menuStructure" :key="index">
        <!-- first level -->
        <Transition v-if="index === 0" :name="transitionName">
          <div v-show="menuLevel === 0" class="absolute inset-0">
            <div
              class="mt-[1px] rounded-bl-2xl rounded-br-2xl bg-blue-lighter px-4 pb-9 pt-4"
            >
              <Logo v-if="lang === 'sv'" class="h-[38px] w-[95px]" />
              <LogoUk v-else-if="lang === 'uk'" class="h-[38px] w-[95px]" />
              <LogoEn v-else-if="lang === 'en'" class="h-[38px] w-[95px]" />
              <SearchInput v-if="!config.public.hideSearch" class="mt-9" />
            </div>
            <template v-for="menuList in item" :key="menuList.id">
              <div class="my-16 pl-8 pr-14">
                <ul class="flex flex-col items-end space-y-7">
                  <li v-for="(menuItem, i) in menuList.child" :key="i">
                    <NuxtLink
                      v-if="menuItem.link"
                      :to="menuItem.link"
                      :target="menuItem.external ? '_blank' : '_self'"
                      class="flex items-center text-right uppercase text-grey-darker"
                      :class="[
                        menuItem.field_style === 'strong'
                          ? 'font-serif text-2xl font-bold '
                          : 'font-sans text-base',
                      ]"
                      @click="closeMenu"
                    >
                      {{ menuItem.title }}
                      <div>
                        <ArrowRight class="ml-5 text-blue" />
                      </div>
                    </NuxtLink>
                    <span
                      v-else
                      class="flex items-center text-right uppercase text-grey-darker"
                      :class="[
                        menuItem.field_style === 'strong'
                          ? 'font-serif text-2xl font-bold'
                          : 'font-sans text-base',
                      ]"
                      @click="openMenu(menuItem)"
                    >
                      {{ menuItem.title }}
                      <PlusIcon class="ml-5 mr-7 shrink-0" />
                    </span>
                  </li>
                </ul>
              </div>
              <div>
                <div class="px-4">
                  <Cta
                    v-if="menuList.cta"
                    class="!mx-0"
                    :sub-title="menuList.cta?.subtitle"
                    :title="menuList.cta?.title"
                    :content="menuList.cta?.copy"
                    :link="menuList.cta?.link"
                    :has-form-component="menuList.cta?.hasFormComponent"
                    :form-component-id="menuList.cta?.formComponentId"
                  >
                    <template #default="{ formComponentId }">
                      <CtaFormComponent :form-component-id="formComponentId" />
                    </template>
                  </Cta>
                </div>

                <Image
                  v-if="menuList?.image?.url"
                  :url="menuList?.image?.url"
                  :alt="menuList?.image?.alt"
                  class="-mt-12 aspect-[390/310]"
                />
              </div>
            </template>
            <!-- footer -->
            <div class="bg-blue-dark">
              <div class="p-16">
                <span
                  v-if="footerData?.row_two_left_heading"
                  class="font-serif text-2xl font-bold leading-none text-white"
                >
                  {{ footerData?.row_two_left_heading }}
                </span>
                <div
                  v-if="footerData?.row_two_left_copy?.value"
                  class="my-10 font-sans text-base text-white"
                  v-html="footerData?.row_two_left_copy?.value"
                ></div>
                <LinkSecondary
                  v-if="footerData?.row_two_left_link_int"
                  :title="footerData?.row_two_left_link_text"
                  :link="footerData?.row_two_left_link_int"
                />
              </div>
              <Divider class="mx-auto w-[85%]" />
              <div class="px-8 py-16">
                <div
                  v-if="footerData?.row_one_right_heading"
                  class="mb-9 font-serif text-2xl font-bold text-white lg:mb-4 lg:text-3xl"
                >
                  {{ footerData?.row_one_right_heading }}
                </div>
                <div class="flex flex-col">
                  <!-- Temporary hides swish widget -->
                  <!--<input
                    class="mb-6 flex-1 rounded-lg border-2 border-r-0 border-blue bg-[#ECFCFF] p-[18px] text-center font-serif text-base font-bold leading-6 text-blue outline-0 ring-0 focus:border-blue focus:ring-0 lg:max-w-[400px] lg:leading-6"
                    value=""
                      />-->
                  <!---<Button title="swisha din gåva" />-->
                  <Link
                    title="Ge en gåva"
                    class="hover:bg-blue-lighter hover:text-blue lg:max-w-[400px]"
                    link="https://interact.djurensratt.se/gava/standard"
                    :external="true"
                  />
                  <LinkSecondary
                    v-if="footerData?.row_one_right_link_int"
                    :title="footerData?.row_one_right_link_text"
                    :link="footerData?.row_one_right_link_int"
                    class="mt-16"
                  />
                </div>
                <div class="mt-16 flex items-center justify-between">
                  <nuxt-img
                    v-for="image in getImagesCloudinary(footerData?.row_one_right_images)"
                    :key="image.url"
                    :src="image.url"
                    alt="Logo"
                    class="max-h-12"
                    loading="lazy"
                  />
                </div>
              </div>
              <Divider class="mx-auto w-[85%]" />
              <div class="flex flex-col items-center py-16 lg:py-20">
                <span class="mb-12 font-serif text-2xl font-bold text-white lg:hidden">
                  Följ oss
                </span>
                <SocialLinks :links="footerData?.row_three_socmed_urls" />
                <LanguageSwitcher
                  class-text="text-white"
                  class-icon="text-white mr-2"
                  class="my-20"
                />
                <nuxt-img
                  v-for="image in getImagesCloudinary(footerData?.row_one_left_logo)"
                  :key="image.url"
                  :src="image.url"
                  alt="Logo"
                  class="mt-20 w-80 cursor-pointer lg:mb-16"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </Transition>
        <!-- second level -->
        <Transition v-else :name="transitionName">
          <div v-show="menuLevel === index" class="absolute inset-0">
            <template v-for="menuList in item" :key="menuList.id">
              <div v-show="menuList.id === activeMenu.id">
                <div class="py-20 pl-8 pr-14">
                  <ul class="flex flex-col items-end space-y-7">
                    <li v-for="(menuItem, i) in menuList.child" :key="i">
                      <NuxtLink
                        v-if="menuItem.link"
                        :to="menuItem.link"
                        :target="menuItem.external ? '_blank' : '_self'"
                        class="flex items-center text-right uppercase text-grey-darker"
                        :class="[
                          menuItem.field_style === 'strong'
                            ? 'font-serif text-2xl font-bold leading-none'
                            : 'font-sans text-base',
                        ]"
                        @click="closeMenu"
                      >
                        {{ menuItem.title }}
                        <div>
                          <ArrowRight class="ml-5 text-blue" />
                        </div>
                      </NuxtLink>
                      <span
                        v-else
                        class="flex items-center text-right uppercase text-grey-darker"
                        :class="[
                          menuItem.field_style === 'strong'
                            ? 'font-serif text-2xl font-bold leading-none'
                            : 'font-sans text-base',
                        ]"
                        @click="openMenu(menuItem)"
                      >
                        {{ menuItem?.title }}
                        <PlusIcon class="ml-5 mr-7 shrink-0" />
                      </span>
                    </li>
                  </ul>
                </div>
                <div
                  v-if="!!menuList?.bottomLinks?.length"
                  class="flex flex-col items-center space-y-4 pb-16"
                >
                  <Link
                    v-for="link in menuList?.bottomLinks"
                    :key="link?.id"
                    :title="link?.title"
                    :link="link?.url"
                    type="outline"
                    :external="link?.type === 'ext'"
                  />
                </div>
                <div>
                  <div class="px-4">
                    <Cta
                      v-if="menuList.cta"
                      class="!mx-0"
                      :sub-title="menuList.cta?.subtitle"
                      :title="menuList.cta?.title"
                      :content="menuList.cta?.copy"
                      :link="menuList.cta?.link"
                      :has-form-component="menuList.cta?.hasFormComponent"
                      :form-component-id="menuList.cta?.formComponentId"
                    >
                      <template #default="{ formComponentId }">
                        <CtaFormComponent :form-component-id="formComponentId" />
                      </template>
                    </Cta>
                  </div>
                  <Image
                    v-if="menuList?.image?.url"
                    :url="menuList?.image?.url"
                    :alt="menuList?.image?.alt"
                    class="-mt-12 aspect-[390/310]"
                  />
                </div>
              </div>
            </template>
          </div>
        </Transition>
      </template>
    </div>
  </Transition>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRuntimeConfig, useRoute, useNuxtApp } from '#app';
import { buildUrl } from 'cloudinary-build-url';
import { Cta } from 'refresh-ui';
import { useMenu, menuState } from 'refresh-common';
import { Logo, LogoEn, LogoUk, Link } from 'refresh-ui';
import SearchInput from '@/components/molecules/Menu/SearchInput.vue';
import PlusIcon from '@/components/atoms/Icons/Plus.vue';
import ArrowRight from '@/components/atoms/Icons/ArrowRight.vue';
import Image from '@/components/atoms/Image.vue';
import Divider from '@/components/atoms/Divider.vue';
import SocialLinks from '@/components/molecules/SocialLinks.vue';
import LanguageSwitcher from '@/components/molecules/LanguageSwitcher.vue';
import LinkSecondary from '@/components/atoms/LinkSecondary.vue';
import CtaFormComponent from '@/components/molecules/CtaFormComponent/index.vue';

import { sleep } from 'refresh-common';
import { flagError } from 'error-handling';

const { $rollbar } = useNuxtApp();
const lang = useI18n().locale.value;

const props = defineProps({
  menuData: Object,
  footerData: Object,
});

const config = useRuntimeConfig();
const route = useRoute();

const { closeMenuMain } = useMenu();

const menuStructure = ref([]);

const getRelationships = id => {
  return props?.menuData?.included
    ? props?.menuData?.included.find(component => component.id === id)
    : {};
};

const getImagesCloudinary = (images, options) => {
  if (!images || images.length === 0) return;

  return images.map(image => {
    const url = buildUrl(image, {
      cloud: {
        cloudName: config.public.cloudname,
      },
      ...options,
    });

    return {
      type: 'image',
      url,
    };
  });
};

const getImage = data => {
  if (!data) return;

  const copy = JSON.parse(JSON.stringify(data));

  const id = Array.isArray(copy) && copy.length > 0 ? copy[0]?.id : copy?.id;

  if (copy.length === 0) return;

  const image = getRelationships(id);
  return {
    alt: image.relationships?.field_media_image?.data?.meta?.alt,
    url: buildUrl(image?.attributes?.computed_cloudinary, {
      cloud: {
        cloudName: config.public.cloudname,
      },
      transformations: {
        format: 'webp',
        resize: {
          width: 1300,
        },
      },
    }),
  };
};

const getLinks = data => {
  if (!data) return;

  const copy = JSON.parse(JSON.stringify(data));

  const links = copy.map(el => {
    const linkData = getRelationships(el.id);
    return {
      id: linkData?.id,
      type: linkData?.attributes?.field_link_type,
      title: linkData?.attributes?.field_link_text,
      url:
        linkData?.attributes?.field_link_type === 'ext'
          ? linkData?.attributes?.field_link_ext?.uri
          : linkData?.attributes?.computed_link_int || '#',
    };
  });

  return links;
};

const getMenu = id => {
  const menu = props.menuData.data
    .filter(item => item.attributes.parent === id)
    .map(child => ({
      id: child.id,
      title: child.attributes.title,
      field_style: child.attributes.field_style,
      parentId: child.attributes.parent,
      external:
        getMenu(child.id).length === 0 ? child.attributes.options.external : false,
      link: getMenu(child.id).length === 0 ? child.attributes.url : null,
      image: getImage(child?.relationships?.field_media?.data),
      cta: getCta(child?.relationships?.field_entities?.data),
    }));
  return menu;
};

const nextLevel = (parentId, level) => {
  const menu = props.menuData.data
    .filter(item => item.attributes.parent === parentId)
    .map(item => ({
      id: item?.id,
      title: item.attributes.title,
      field_style: item.attributes.field_style,
      level,
      parentId,
      child: getMenu(item?.id),
      image: getImage(item.relationships?.field_media?.data),
      bottomLinks: getLinks(item.relationships?.field_link.data),
      cta: getCta(item.relationships?.field_entities?.data),
    }));

  if (menu.length === 0) return;

  if (!menuStructure.value[level]) {
    menuStructure.value.push(menu);
  } else {
    menuStructure.value[level].push(...menu);
  }

  menu.forEach(element => nextLevel(element.id, level + 1));
};

const getLink = data => {
  if (!data) return;

  const copy = JSON.parse(JSON.stringify(data));
  const id = Array.isArray(copy) && copy.length > 0 ? copy[0].id : copy?.id;

  if (copy.length === 0) return;

  const link = getRelationships(id);

  return {
    type: link.attributes.field_link_type,
    title: link.attributes.field_link_text,
    url:
      link.attributes.field_link_type === 'ext'
        ? link.attributes.field_link_ext.uri
        : link.attributes.computed_link_int,
  };
};

const getCta = data => {
  if (!data) return;

  const copy = JSON.parse(JSON.stringify(data));

  const id = Array.isArray(copy) && copy.length > 0 ? copy[0].id : copy?.id;

  if (copy.length === 0) return;

  const cta = getRelationships(id);
  return {
    id: cta.id,
    title: cta.attributes.title,
    subtitle: cta.attributes.field_subtitle,
    copy: cta.attributes.field_wysiwyg.processed,
    link: getLink(cta?.relationships?.field_link?.data),
    hasFormComponent: cta?.attributes?.field_form_type === 'component',
    formComponentId: cta?.attributes?.field_form_component,
  };
};

const makeMenu = () => {
  try {
    const firstLevel = props?.menuData?.data.find(item => item.attributes.parent === '');
    menuStructure.value.push([
      {
        id: firstLevel?.id,
        image: getImage(firstLevel.relationships?.field_media?.data),
        child: getMenu(firstLevel.id),
        cta: getCta(firstLevel.relationships?.field_entities?.data),
      },
    ]);

    nextLevel(firstLevel.id, 1);
  } catch (error) {
    flagError(error, 'MenuMobile/makeMenu', $rollbar);
    console.log(error);
  }
};

makeMenu();

const activeMenu = ref(menuStructure.value?.[0]?.[0]);
const menuLevel = ref(0);

const transitionName = ref('forward');

const openMenu = async menu => {
  transitionName.value = 'forward';
  menuLevel.value = menuLevel.value + 1;
  await sleep(100);
  activeMenu.value = menu;
};
const closeMenu = async () => {
  transitionName.value = 'close';
  closeMenuMain();
  menuLevel.value = 0;
  await sleep(300);
  activeMenu.value = menuStructure.value[0][0];
};
const goBack = async () => {
  transitionName.value = 'back';
  menuLevel.value = menuLevel.value - 1;
  await sleep(500);
  const menu = menuStructure.value[menuLevel.value].find(
    item => item.id === activeMenu.value.parentId,
  );
  await sleep(100);
  activeMenu.value = menu;
};

watch(
  () => route.path,
  () => closeMenu(),
);
</script>

<style>
.forward-enter-active {
  transition: all 0.8s ease-out;
}

.forward-leave-active {
  transition: all 0.8s ease-out;
}

.forward-enter-from {
  transform: translateX(150%);
  opacity: 0;
}

.forward-leave-to {
  transform: translateX(-150%);
  opacity: 0;
}

.back-enter-active {
  transition: all 0.8s ease-out;
}

.back-leave-active {
  transition: all 0.8s ease-out;
}

.back-enter-from {
  transform: translateX(-150%);
  opacity: 0;
}

.back-leave-to {
  transform: translateX(150%);
  opacity: 0;
}

.close-leave-to {
  opacity: 0;
}

.close-leave-active {
  transition: all 0.4s ease-out;
}
</style>
