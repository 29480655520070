<template>
  <div
    class="grid grid-cols-3 flex-wrap items-center justify-between gap-x-9 gap-y-16 lg:flex lg:justify-center"
  >
    <NuxtLink
      v-for="link in links"
      :key="link"
      :to="link"
      :aria-label="link"
      class="cursor-pointer text-white hover:text-blue-light lg:mr-8"
      target="_blank"
    >
      <component :is="getIcon(link)" />
    </NuxtLink>
  </div>
</template>

<script setup>
import Linkedin from '@/components/atoms/Icons/Linkedin.vue';
import TikTok from '@/components/atoms/Icons/TikTok.vue';
import Facebook from '@/components/atoms/Icons/Facebook.vue';
import Instagram from '@/components/atoms/Icons/Instagram.vue';
import Twitter from '@/components/atoms/Icons/Twitter.vue';
import YouTube from '@/components/atoms/Icons/YouTube.vue';

defineProps({
  links: Array,
});

const getIcon = link => {
  const linkLowerCase = link.toLowerCase();

  if (linkLowerCase.includes('youtube')) {
    return YouTube;
  }
  if (linkLowerCase.includes('tiktok')) {
    return TikTok;
  }
  if (linkLowerCase.includes('instagram')) {
    return Instagram;
  }
  if (linkLowerCase.includes('twitter')) {
    return Twitter;
  }
  if (linkLowerCase.includes('facebook')) {
    return Facebook;
  }
  if (linkLowerCase.includes('linkedin')) {
    return Linkedin;
  }
};
</script>
