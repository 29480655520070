<template>
  <Transition
    :duration="{ enter: 500, leave: 300 }"
    enter-from-class="opacity-0"
    leave-to-class="opacity-0"
    enter-active-class="transition-opacity duration-500"
    leave-active-class="transition-opacity duration-300"
  >
    <div v-show="menuState.menu" class="fixed inset-0 z-50 hidden bg-white xl:block">
      <template v-for="image in images" :key="image.id">
        <Transition
          enter-from-class="opacity-0"
          leave-to-class="opacity-0"
          enter-active-class="transition duration-500"
          leave-active-class="transition duration-500"
        >
          <nuxt-img
            v-if="activeImage === image.id"
            :src="image.url"
            :alt="image.alt"
            class="absolute inset-0 h-full w-full object-cover object-center"
            loading="lazy"
          />
        </Transition>
      </template>

      <div class="relative z-50 flex h-full">
        <div
          class="relative flex h-full flex-1 basis-1/2 flex-col justify-end p-8 2xl:p-11"
        >
          <template v-for="block in CTABlocks" :key="block.id">
            <Transition
              enter-from-class="opacity-0 "
              leave-to-class="opacity-0"
              enter-active-class="transition duration-700"
              leave-active-class="transition duration-700"
            >
              <Cta
                v-show="block.id === activeCTABlock"
                class="!absolute bottom-11 w-[600px]"
                :sub-title="block.subtitle"
                :title="block.title"
                :content="block.copy"
                :link="block?.link"
                :has-form-component="block?.hasFormComponent"
                :form-component-id="block?.formComponentId"
              >
                <template #default="{ formComponentId }">
                  <CtaFormComponent :form-component-id="formComponentId" />
                </template>
              </Cta>
            </Transition>
          </template>
        </div>
        <div class="relative flex basis-1/2 justify-end">
          <Transition
            enter-from-class="translate-x-[150%] opacity-0"
            leave-to-class="translate-x-[150%] opacity-0"
            enter-active-class="transition duration-700"
            leave-active-class="transition duration-700"
          >
            <div
              v-show="menuState.menu"
              class="absolute right-0 flex h-full flex-1 flex-col justify-between overflow-hidden rounded-bl-[70px] rounded-tl-[70px] bg-white xl:w-[650px] 2xl:w-[700px]"
            >
              <button
                class="absolute right-20 top-20 z-50 -m-1 p-2 hover:text-blue-dark"
                @click="closeMenu"
              >
                <AtomsIconsClose />
              </button>
              <Transition
                enter-from-class="opacity-0"
                leave-to-class="opacity-0"
                enter-active-class="transition duration-200 delay-300"
                leave-active-class="transition duration-100"
              >
                <button
                  v-show="menuLevel > 0"
                  class="absolute left-20 top-20 z-50 p-2 text-black hover:text-blue-dark"
                  @click="goBack"
                >
                  <AtomsIconsArrowLeft />
                </button>
              </Transition>

              <template v-for="(item, index) in menuStructure" :key="index">
                <!-- first level -->
                <Transition v-if="index === 0" :name="transitionName">
                  <div
                    v-show="menuLevel === 0"
                    class="absolute bottom-12 left-20 right-20 top-20 flex flex-1 flex-col"
                  >
                    <div class="flex flex-1 flex-col justify-center">
                      <div class="mr-3 mt-0 pr-16">
                        <template v-for="menuList in item" :key="menuList.id">
                          <ul
                            v-show="menuList.id === activeMenu.id"
                            class="flex flex-col items-end space-y-4"
                          >
                            <li v-for="(menuItem, i) in menuList.child" :key="i">
                              <NuxtLink
                                v-if="menuItem.link"
                                :to="menuItem.link"
                                :target="menuItem.external ? '_blank' : '_self'"
                                class="group flex cursor-pointer items-center text-right uppercase leading-snug"
                                :class="[
                                  menuItem.field_style === 'strong'
                                    ? 'font-serif text-4xl font-bold   text-grey-darker hover:text-blue-dark'
                                    : 'font-sans text-xl  text-grey-darker hover:text-blue-dark',
                                ]"
                                @click="closeMenu"
                              >
                                <span
                                  class="transition-all duration-300 group-hover:-translate-x-1"
                                >
                                  {{ menuItem.title }}
                                </span>
                                <div>
                                  <ArrowRight class="ml-5 text-blue" />
                                </div>
                              </NuxtLink>
                              <span
                                v-else
                                class="group flex cursor-pointer items-center text-right uppercase leading-snug"
                                :class="[
                                  menuItem.field_style === 'strong'
                                    ? 'font-serif text-4xl font-bold   text-grey-darker hover:text-blue-dark'
                                    : 'font-sans text-xl  text-grey-darker hover:text-blue-dark',
                                ]"
                                @click="openMenu(menuItem)"
                              >
                                <span
                                  class="transition-all duration-300 group-hover:-translate-x-1"
                                >
                                  {{ menuItem.title }}
                                </span>
                                <PlusIcon class="ml-5 mr-7 shrink-0" />
                              </span>
                            </li>
                          </ul>
                        </template>
                      </div>
                    </div>
                    <div class="mt-auto">
                      <div class="mb-20">
                        <SearchInput />
                      </div>
                      <div class="flex justify-between">
                        <div>
                          <LanguageSwitcher
                            class="ml-20 pr-10 text-base !font-normal text-blue transition-colors duration-300 hover:!text-blue-dark lg:text-base"
                            class-icon="h-5"
                          />
                        </div>
                        <SocialLinksMenu class="mr-14" :links="socialLinks" />
                      </div>
                    </div>
                  </div>
                </Transition>
                <!-- nested level -->
                <Transition v-else :name="transitionName">
                  <div
                    v-show="menuLevel === index"
                    class="absolute bottom-12 left-20 right-20 top-20 flex flex-col justify-between"
                  >
                    <template v-for="menuList in item" :key="menuList.id">
                      <div
                        v-show="menuList.id === activeMenu.id"
                        class="mr-3 mt-20 overflow-y-scroll pr-16 scrollbar-thin scrollbar-track-grey-lighter scrollbar-thumb-blue scrollbar-track-rounded-full scrollbar-thumb-rounded-full xl:mb-20"
                      >
                        <ul class="mb-10 flex flex-col items-end space-y-4">
                          <li v-for="(menuItem, i) in menuList.child" :key="i">
                            <NuxtLink
                              v-if="menuItem.link"
                              :to="menuItem.link"
                              :target="menuItem.external ? '_blank' : '_self'"
                              class="group flex cursor-pointer items-center text-right uppercase"
                              :class="[
                                menuItem.field_style === 'strong'
                                  ? 'font-serif text-4xl font-bold leading-none text-grey-darker hover:text-blue-dark'
                                  : 'mt-3  font-sans text-xl text-grey-darker hover:text-blue-dark',
                              ]"
                              @click="closeMenu"
                            >
                              <span
                                class="transition-all duration-300 group-hover:-translate-x-1"
                              >
                                {{ menuItem.title }}
                              </span>
                              <div>
                                <ArrowRight class="ml-5 text-blue" />
                              </div>
                            </NuxtLink>
                            <span
                              v-else
                              class="group flex cursor-pointer items-center text-right uppercase"
                              :class="[
                                menuItem.field_style === 'strong'
                                  ? 'font-serif text-4xl font-bold leading-none text-grey-darker hover:text-blue-dark'
                                  : 'mt-3 font-sans  text-xl text-grey-darker hover:text-blue-dark',
                              ]"
                              @click="openMenu(menuItem)"
                            >
                              <span
                                class="transition-all duration-300 group-hover:-translate-x-1"
                              >
                                {{ menuItem.title }}
                              </span>
                              <PlusIcon class="ml-5 mr-7 shrink-0" />
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div
                        v-show="menuList.id === activeMenu.id"
                        class="absolute block h-14 w-[95%] bg-gradient-to-t from-white"
                        :class="[
                          menuList?.bottomLinks.length > 0
                            ? 'bottom-[120px]'
                            : 'bottom-[70px]',
                        ]"
                      ></div>

                      <div
                        v-show="menuList.id === activeMenu.id"
                        class="flex justify-center space-x-4"
                      >
                        <Link
                          v-for="link in menuList?.bottomLinks"
                          :key="link.id"
                          :title="link.title"
                          :link="link.url"
                          type="outline"
                          class="flex max-w-[50%] flex-1 justify-center px-4 text-sm lg:px-4 lg:!text-sm"
                          :external="link.type === 'ext'"
                        />
                      </div>
                    </template>
                  </div>
                </Transition>
              </template>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRuntimeConfig, useRoute, useNuxtApp } from '#app';
import { buildUrl } from 'cloudinary-build-url';
import { Cta } from 'refresh-ui';
import { useMenu, menuState } from 'refresh-common';
import { sleep } from 'refresh-common';
import { Link } from 'refresh-ui';

import LanguageSwitcher from '@/components/molecules/LanguageSwitcher.vue';
import SocialLinksMenu from '@/components/molecules/SocialLinksMenu.vue';
import PlusIcon from '@/components/atoms/Icons/Plus.vue';
import ArrowRight from '@/components/atoms/Icons/ArrowRight.vue';
import SearchInput from '@/components/molecules/Menu/SearchInput.vue';
import CtaFormComponent from '@/components/molecules/CtaFormComponent/index.vue';
import { flagError } from 'error-handling';

const { $rollbar } = useNuxtApp();

const props = defineProps({
  menuData: Object,
  socialLinks: Array,
});

const config = useRuntimeConfig();
const route = useRoute();
const { closeMenuMain } = useMenu();

const menuStructure = ref([]);
const images = ref([]);
const CTABlocks = ref([]);

const getRelationships = id => {
  return props?.menuData?.included
    ? props?.menuData?.included.find(component => component.id === id)
    : {};
};

const getLinks = data => {
  if (!data) return;

  const copy = JSON.parse(JSON.stringify(data));

  const links = copy.map(el => {
    const linkData = getRelationships(el.id);
    return {
      id: linkData?.id,
      type: linkData?.attributes?.field_link_type,
      title: linkData?.attributes?.field_link_text,
      url:
        linkData?.attributes?.field_link_type === 'ext'
          ? linkData?.attributes?.field_link_ext.uri
          : linkData?.attributes?.computed_link_int || '#',
    };
  });

  return links;
};

const getLink = data => {
  if (!data) return;

  const copy = JSON.parse(JSON.stringify(data));
  const id = Array.isArray(copy) && copy.length > 0 ? copy[0].id : copy?.id;

  if (copy.length === 0) return;

  const link = getRelationships(id);

  return {
    type: link.attributes.field_link_type,
    title: link.attributes.field_link_text,
    url:
      link.attributes.field_link_type === 'ext'
        ? link.attributes.field_link_ext.uri
        : link.attributes.computed_link_int,
  };
};

const getId = data => {
  if (!data) return;
  const copy = JSON.parse(JSON.stringify(data));
  const id = Array.isArray(copy) && copy.length > 0 ? copy[0].id : copy?.id;
  return id;
};

const getMenu = id => {
  const menu = props.menuData.data
    .filter(item => item.attributes.parent === id)
    .map(child => ({
      id: child.id,
      title: child.attributes.title,
      field_style: child.attributes.field_style,
      parentId: child.attributes.parent,
      external:
        getMenu(child.id).length === 0 ? child.attributes.options.external : false,
      link: getMenu(child.id).length === 0 ? child.attributes.url : null,
      image: getId(child.relationships?.field_media?.data),
      cta: getId(child.relationships?.field_entities?.data),
    }));
  return menu;
};

const nextLevel = (parentId, level) => {
  const menu = props.menuData.data
    .filter(item => item.attributes.parent === parentId)
    .map(item => ({
      id: item.id,
      title: item.attributes.title,
      field_style: item.attributes.field_style,
      level,
      parentId,
      child: getMenu(item.id),
      image: getId(item.relationships?.field_media?.data),
      bottomLinks: getLinks(item.relationships?.field_link.data),
      cta: getId(item.relationships?.field_entities?.data),
    }));

  if (menu.length === 0) return;

  if (!menuStructure.value[level]) {
    menuStructure.value.push(menu);
  } else {
    menuStructure.value[level].push(...menu);
  }

  menu.forEach(element => nextLevel(element.id, level + 1));
};

const makeMenu = () => {
  try {
    const firstLevel = props.menuData?.data?.find(item => item.attributes.parent === '');
    menuStructure.value.push([
      {
        id: firstLevel.id,
        image: getId(firstLevel.relationships?.field_media?.data),
        child: getMenu(firstLevel.id),
        cta: getId(firstLevel.relationships?.field_entities?.data),
      },
    ]);

    nextLevel(firstLevel.id, 1);
  } catch (error) {
    flagError(error, 'MenuDesktop/makeMenu', $rollbar);
    console.log(error);
  }
};

const getImages = () => {
  const filteredImage = props?.menuData?.included
    ? props?.menuData?.included.filter(el => el.type === 'media--image')
    : [];

  const image = filteredImage.map(image => ({
    id: image?.id,
    alt: image.relationships?.field_media_image?.data?.meta?.alt,
    url: buildUrl(image?.attributes?.computed_cloudinary, {
      cloud: {
        cloudName: config.public.cloudname,
      },
      transformations: {
        format: 'webp',
        resize: {
          width: 2000,
        },
      },
    }),
  }));
  images.value = image;
};

const getCTABlocks = () => {
  const menuCta = props?.menuData?.included
    ? props?.menuData?.included.filter(el => el.type === 'node--menu_cta')
    : [];
  const blocks = menuCta.map(block => ({
    id: block?.id,
    title: block?.attributes?.title,
    subtitle: block.attributes?.field_subtitle,
    copy: block.attributes?.field_wysiwyg?.processed,
    link: getLink(block?.relationships?.field_link?.data),
    hasFormComponent: block?.attributes?.field_form_type === 'component',
    formComponentId: block?.attributes?.field_form_component,
  }));

  CTABlocks.value = blocks;
};

makeMenu();
getImages();
getCTABlocks();

const activeMenu = ref(menuStructure.value?.[0]?.[0]);
const activeImage = ref(menuStructure.value?.[0]?.[0]?.image);
const activeCTABlock = ref(menuStructure.value?.[0]?.[0]?.cta);

const menuLevel = ref(0);
const transitionName = ref('forward');

const openMenu = async menu => {
  transitionName.value = 'forward';
  menuLevel.value = menuLevel.value + 1;
  activeImage.value = menu.image;
  activeCTABlock.value = menu.cta;
  await sleep(400);
  activeMenu.value = menu;
};

const closeMenu = async () => {
  closeMenuMain();
  menuLevel.value = 0;
  transitionName.value = 'forward';
  await sleep(500);
  activeMenu.value = menuStructure.value[0][0];
  activeImage.value = menuStructure.value[0][0].image;
  activeCTABlock.value = menuStructure.value[0][0].cta;
};
const goBack = async () => {
  transitionName.value = 'back';
  menuLevel.value = menuLevel.value - 1;

  const menu = menuStructure.value[menuLevel.value].find(
    item => item.id === activeMenu.value.parentId,
  );
  activeCTABlock.value = menu.cta;
  activeImage.value = menu.image;
  await sleep(400);

  activeMenu.value = menu;
};

watch(
  () => route.path,
  () => closeMenu(),
);
</script>

<style>
.forward-enter-active {
  transition: all 0.6s ease-out;
}

.forward-leave-active {
  transition: all 0.6s ease-out;
}

.forward-enter-from {
  transform: translateX(150%);
  opacity: 0;
}
.forward-leave-to {
  transform: translateX(-150%);
  opacity: 0;
}

.back-enter-active {
  transition: all 0.6s ease-out;
}

.back-leave-active {
  transition: all 0.6s ease-out;
}

.back-enter-from {
  transform: translateX(-150%);
  opacity: 0;
}

.back-leave-to {
  transform: translateX(150%);
  opacity: 0;
}
</style>
